import * as React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core";

import Layout from "../components/layout";
import Seo from "../components/seo";
import BreadCrumbs from "../components/breadCrumbs";
import MembershipContent from "../components/membership/template/membershipContent";

const useStyles = makeStyles(({ theme }) => ({
  root: {
    width: "100%",
    marginTop: "4.166vw",
    padding: "0 3.47vw",
    "@media(min-width: 1440px)": {
      padding: "0 50px",
      marginTop: 60,
    },
    "@media(max-width: 767px)": {
      padding: 0,
      marginTop: "4.83vw",
    },
  },
}));

const MembershipPoolPage = ({ data: { prismicMembershipPool } }) => {
  const classes = useStyles();
  const poolData = prismicMembershipPool.data;
  return (
    <Layout>
      <Seo title="Абонемент" />
      <BreadCrumbs
        links={[
          { title: "Абонементы"},
          {
            title: poolData.name,
            href: `/memberships/${prismicMembershipPool.uid}`,
          },
        ]}
      />

      <div className={classes.root}>
        <MembershipContent data={poolData} type={"pool"} />
      </div>
    </Layout>
  );
};

/**
 * Страница Абонемента
 * @module src/templates/membership
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученный из prismic
 */
export default MembershipPoolPage;

export const query = graphql`
  query MembershipPoolPage($uid: String!) {
    prismicMembershipPool(uid: { eq: $uid }) {
      data {
        expiration_time
        gym_time
        name
        pool_size_25
        pool_size_50
        pool_size_padding
        pool_time
        tag_age
        tag_category
        tag_type
        lessons_cost {
          price
          weekly_count
        }
        addresses {
          address {
            document {
              ... on PrismicAddress {
                data {
                  name
                }
              }
            }
          }
        }
        images {
          image {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
